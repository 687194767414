import { EditBase, EditProps } from 'react-admin';

import CreateEditWrapper from 'app/layout/CreateEditWrapper';
import { Region } from './RegionsTypeInterface';
import RegionForm from './RegionForm';
import { trimStrings } from 'app/utils/functions';

interface IRegionEdit extends EditProps<Region> {
  onCancel: () => void;
  id?: string;
}

const RegionEdit = ({ onCancel, id, ...props }: IRegionEdit) => (
  <EditBase {...props} id={id} transform={trimStrings}>
    <CreateEditWrapper redirectTo='regions' title='Region' isCreate={false} onCancel={onCancel}>
      <RegionForm />
    </CreateEditWrapper>
  </EditBase>
);

export default RegionEdit;
