import { Identifier, Datagrid, TextField, BooleanField } from 'react-admin';

import BulkActionButtons from 'app/layout/BulkActionButtons';
import rowStyle from 'app/layout/rowStyle';
import IdArrayField from 'app/components/IdArrayField';
import { Product } from './ProductsTypeInterface';

export interface IProductListDesktop {
  selectedRow?: Identifier;
}

const ProductsListDesktop = ({ selectedRow }: IProductListDesktop) => (
  <Datagrid
    rowClick='edit'
    rowStyle={rowStyle(selectedRow)}
    optimized
    bulkActionButtons={<BulkActionButtons />}
    isRowSelectable={(record: Product) => !record.archived}
  >
    <TextField source='skuId' label='Product Id' />
    <IdArrayField source='techniqueIds' name='techniques' isFiltered />
    <BooleanField source='active' />
    <BooleanField source='archived' />
  </Datagrid>
);

export default ProductsListDesktop;
