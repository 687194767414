import { CreateBase, CreateProps } from 'react-admin';

import CreateEditWrapper from 'app/layout/CreateEditWrapper';
import { Region } from './RegionsTypeInterface';
import RegionForm from './RegionForm';
import { trimStrings } from 'app/utils/functions';

interface IRegionCreate extends CreateProps<Region> {
  onCancel: () => void;
}

const RegionCreate = ({ onCancel, ...props }: IRegionCreate) => (
  <CreateBase {...props} transform={trimStrings}>
    <CreateEditWrapper redirectTo='regions' title='Region' isCreate onCancel={onCancel}>
      <RegionForm />
    </CreateEditWrapper>
  </CreateBase>
);

export default RegionCreate;
