import { CreateProps, CreateBase } from 'react-admin';

import CreateEditWrapper from 'app/layout/CreateEditWrapper';
import { Product } from './ProductsTypeInterface';
import ProductForm from './ProductForm';
import { trimStrings } from 'app/utils/functions';

interface IProductCreate extends CreateProps<Product> {
  onCancel: () => void;
}

const ProductCreate = ({ onCancel, ...props }: IProductCreate) => (
  <CreateBase {...props} transform={trimStrings}>
    <CreateEditWrapper redirectTo='products' title='Product' isCreate onCancel={onCancel}>
      <ProductForm isCreate />
    </CreateEditWrapper>
  </CreateBase>
);

export default ProductCreate;
