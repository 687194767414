import { TextInput, required, SelectArrayInput } from 'react-admin';

import useGetResource from 'app/utils/hooks/useGetResource';
import { Country } from './RegionsTypeInterface';

const RegionForm = () => {
  const countries = useGetResource<Country[]>({
    source: `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_AUTH_API_VERSION}/currencies?filter={"active": true}`,
  });

  return (
    <>
      <TextInput source='id' validate={required()} />
      <TextInput source='label' validate={required()} />
      <SelectArrayInput
        key={'countries'}
        source='countries'
        choices={countries.filter((i) => !i.default && i.active)}
        validate={required()}
        optionText='countryName'
        optionValue='countryIsoCode'
      />
    </>
  );
};

export default RegionForm;
