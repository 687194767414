import { EditBase, EditProps } from 'react-admin';

import CreateEditWrapper from 'app/layout/CreateEditWrapper';
import { Technique } from './TechniquesTypeInterface';
import TechniqueForm from './TechniqueForm';
import { trimStrings } from 'app/utils/functions';

interface ITechniqueEdit extends EditProps<Technique> {
  onCancel: () => void;
  id?: string;
}

const TechniqueEdit = ({ onCancel, id, ...props }: ITechniqueEdit) => (
  <EditBase {...props} id={id} transform={trimStrings}>
    <CreateEditWrapper redirectTo='techniques' title='Technique' isCreate={false} onCancel={onCancel}>
      <TechniqueForm isCreate={false} />
    </CreateEditWrapper>
  </EditBase>
);

export default TechniqueEdit;
