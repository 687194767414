import { FieldProps, RaRecord, useRecordContext } from 'react-admin';
import { Avatar, SxProps } from '@mui/material';

interface IAvatarField extends FieldProps<RaRecord> {
  sx?: SxProps;
  size?: string;
  source: string;
}

const AvatarField = ({ size = '40', sx, source }: IAvatarField) => {
  const record = useRecordContext<RaRecord>();
  if (!record) return null;
  return (
    <Avatar
      src={`${record[source]}?size=${size}x${size}`}
      style={{ width: parseInt(size, 10), height: parseInt(size, 10) }}
      sx={sx}
    />
  );
};

export default AvatarField;
