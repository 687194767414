import { EditBase, EditProps } from 'react-admin';

import CreateEditWrapper from 'app/layout/CreateEditWrapper';
import { GiftCode } from './GiftCodesTypeInterface';
import GiftCodeForm from './GiftCodeForm';
import { trimStrings } from 'app/utils/functions';

interface IGiftCodeEdit extends EditProps<GiftCode> {
  onCancel: () => void;
  id?: string;
}

const GiftCodeEdit = ({ onCancel, id, ...props }: IGiftCodeEdit) => (
  <EditBase {...props} id={id} transform={trimStrings}>
    <CreateEditWrapper redirectTo='gift-codes' title='Gift Code' isCreate={false} onCancel={onCancel}>
      <GiftCodeForm isCreate={false} />
    </CreateEditWrapper>
  </EditBase>
);

export default GiftCodeEdit;
