import { Admin, Resource } from 'react-admin';

import { Dashboard } from './pages/dashboard';
import Login from './pages/auth/Login';
import { Layout } from './layout';
import { lightTheme } from './layout/themes';
import authProvider from './utils/auth/auth.provider';
import { dataProvider } from './utils/dataProvider';

import users from './pages/users';
import regions from './pages/regions';
import techniques from './pages/techniques';
import products from './pages/products';
import discounts from './pages/discounts';
import giftCodes from './pages/gift-codes';

const App = () => {
  return (
    <Admin
      title=''
      dataProvider={dataProvider}
      authProvider={authProvider}
      dashboard={Dashboard}
      loginPage={Login}
      layout={Layout}
      // i18nProvider={i18nProvider}
      disableTelemetry
      theme={lightTheme}
    >
      <Resource name='users' {...users} />
      <Resource name='regions' {...regions} />
      <Resource name='techniques' {...techniques} />
      <Resource name='products' {...products} />
      <Resource name='discounts' {...discounts} />
      <Resource name='gift-codes' {...giftCodes} />
    </Admin>
  );
};

export default App;
