import { Identifier, Datagrid, TextField, FunctionField, BooleanField } from 'react-admin';

import BulkActionButtons from 'app/layout/BulkActionButtons';
import rowStyle from 'app/layout/rowStyle';
import { Region } from './RegionsTypeInterface';

export interface IRegionListDesktop {
  selectedRow?: Identifier;
}

const RegionsListDesktop = ({ selectedRow }: IRegionListDesktop) => (
  <Datagrid
    rowClick='edit'
    rowStyle={rowStyle(selectedRow)}
    optimized
    bulkActionButtons={<BulkActionButtons />}
    isRowSelectable={(record: Region) => !record.archived}
  >
    <TextField source='id' />
    <TextField source='label' />
    <FunctionField label='Total countries' render={(record: Region) => record.countries.length} sortBy='countries' />
    <BooleanField source='archived' label='Is Archived' />
  </Datagrid>
);

export default RegionsListDesktop;
