import { useCallback } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import { List } from 'react-admin';
import { Box, Drawer } from '@mui/material';

import regionsFilters from './regionsFilters';
import RegionsListDesktop from './RegionsListDesktop';
import RegionEdit from './RegionEdit';
import RegionCreate from './RegionCreate';

const RegionsList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    navigate('/regions');
  }, [navigate]);

  const match = matchPath('/regions/:id', location.pathname);
  const rowNum = match && match.params.id;

  return (
    <Box>
      <List filters={regionsFilters} hasCreate perPage={25} sort={{ field: 'archived', order: 'asc' }}>
        <RegionsListDesktop selectedRow={match ? parseInt(rowNum ? rowNum : '', 10) : undefined} />
      </List>
      <Drawer variant='persistent' open={!!match} anchor='right' onClose={handleClose} sx={{ zIndex: 100 }}>
        {match && rowNum !== 'create' ? (
          <RegionEdit id={match.params.id} onCancel={handleClose} />
        ) : (
          <RegionCreate onCancel={handleClose} />
        )}
      </Drawer>
    </Box>
  );
};

export default RegionsList;
