import { useState } from 'react';
import { DashboardMenuItem, MenuItemLink, MenuProps, useSidebarState } from 'react-admin';
import Box from '@mui/material/Box';

import users from '../pages/users';
import SubMenu from './SubMenu';
import regions from '../pages/regions';
import techniques from '../pages/techniques';
import products from '../pages/products';
import discounts from '../pages/discounts';
import giftCodes from '../pages/gift-codes';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
  });
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />
      <SubMenu
        handleToggle={() => handleToggle('menuSales')}
        isOpen={state.menuSales}
        name='User Management'
        icon={<users.icon />}
        dense={dense}
      >
        <MenuItemLink
          to='/users'
          state={{ _scrollToTop: true }}
          primaryText={'Admin Users'}
          leftIcon={<users.icon />}
          dense={dense}
        />
        <MenuItemLink
          to='/regions'
          state={{ _scrollToTop: true }}
          primaryText={'Regions'}
          leftIcon={<regions.icon />}
          dense={dense}
        />
        <MenuItemLink
          to='/techniques'
          state={{ _scrollToTop: true }}
          primaryText={'Techniques'}
          leftIcon={<techniques.icon />}
          dense={dense}
        />
        <MenuItemLink
          to='/products'
          state={{ _scrollToTop: true }}
          primaryText={'Products'}
          leftIcon={<products.icon />}
          dense={dense}
        />
        <MenuItemLink
          to='/discounts'
          state={{ _scrollToTop: true }}
          primaryText={'Discounts'}
          leftIcon={<discounts.icon />}
          dense={dense}
        />
        <MenuItemLink
          to='/gift-codes'
          state={{ _scrollToTop: true }}
          primaryText={'Gift Codes'}
          leftIcon={<giftCodes.icon />}
          dense={dense}
        />
      </SubMenu>
    </Box>
  );
};

export default Menu;
