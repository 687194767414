import { Box, Card, CardActions, Typography } from '@mui/material';

import publishArticleImage from 'app/assets/images/welcome_illustration.svg';

const Welcome = () => {
  return (
    <Card
      sx={{
        background: (theme) =>
          theme.palette.mode === 'dark'
            ? '#535353'
            : 'linear-gradient(to right, #8975fb 0%, #746be7 35%), linear-gradient(to bottom, #8975fb 0%, #6f4ceb 50%), #6f4ceb',

        color: '#fff',
        padding: '20px',
        marginTop: 2,
        marginBottom: '1em',
      }}
    >
      <Box display='flex'>
        <Box flex='1'>
          <Typography variant='h5' component='h2' gutterBottom>
            {'Welcome'}
          </Typography>
          <Box maxWidth='40em'>
            <Typography variant='body1' component='p' gutterBottom>
              {'OMG Admin Panel'}
            </Typography>
          </Box>
          <CardActions
            sx={{
              padding: { xs: 0, xl: null },
              flexWrap: { xs: 'wrap', xl: null },
              '& a': {
                marginTop: { xs: '1em', xl: null },
                marginLeft: { xs: '0!important', xl: null },
                marginRight: { xs: '1em', xl: null },
              },
            }}
          ></CardActions>
        </Box>
        <Box
          display={{ xs: 'none', sm: 'none', md: 'block' }}
          sx={{
            background: `url(${publishArticleImage}) top right / cover`,
            marginLeft: 'auto',
          }}
          width='16em'
          height='9em'
          overflow='hidden'
        />
      </Box>
    </Card>
  );
};

export default Welcome;
