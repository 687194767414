import { CreateBase, CreateProps } from 'react-admin';

import CreateEditWrapper from 'app/layout/CreateEditWrapper';
import { User } from './UserTypeInterface';
import UserForm from './UserForm';
import { trimStrings } from 'app/utils/functions';

interface IUserCreate extends CreateProps<User> {
  onCancel: () => void;
}

const UserCreate = ({ onCancel, ...props }: IUserCreate) => (
  <CreateBase {...props} transform={trimStrings}>
    <CreateEditWrapper redirectTo='users' title='Admin' isCreate onCancel={onCancel}>
      <UserForm isCreate />
    </CreateEditWrapper>
  </CreateBase>
);

export default UserCreate;
