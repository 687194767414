import { useCallback } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import { List } from 'react-admin';
import { Box, Drawer } from '@mui/material';

import productsFilters from './productsFilters';
import ProductsListDesktop from './ProductsListDesktop';
import ProductEdit from './ProductEdit';
import ProductCreate from './ProductCreate';

const ProductsList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    navigate('/products');
  }, [navigate]);

  const match = matchPath('/products/:id', location.pathname);
  const rowNum = match && match.params.id;

  return (
    <Box>
      <List filters={productsFilters} hasCreate perPage={25} sort={{ field: 'archived', order: 'asc' }}>
        <ProductsListDesktop selectedRow={match ? parseInt(rowNum ? rowNum : '', 10) : undefined} />
      </List>
      <Drawer variant='persistent' open={!!match} anchor='right' onClose={handleClose} sx={{ zIndex: 100 }}>
        {match && rowNum !== 'create' ? (
          <ProductEdit id={match.params.id} onCancel={handleClose} />
        ) : (
          <ProductCreate onCancel={handleClose} />
        )}
      </Drawer>
    </Box>
  );
};

export default ProductsList;
