import { ReactNode } from 'react';
import { SimpleForm } from 'react-admin';
import { Box, Grid, Stack, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import CreateEditToolbar from './CreateEditToolbar';

interface ICreateEditWrapper {
  children: ReactNode;
  redirectTo: string;
  title: string;
  isCreate: boolean;
  onCancel: () => void;
}

const CreateEditWrapper = ({ children, redirectTo, title, isCreate, onCancel }: ICreateEditWrapper) => (
  <Box pt={5} width={{ xs: '100vW', sm: 400 }} mt={{ xs: 2, sm: 1 }}>
    <Stack direction='row' p={2}>
      <Typography variant='h6' flex='1'>
        {`${isCreate ? 'Create' : 'Edit'} ${title}`}
      </Typography>
      <IconButton onClick={onCancel} size='small'>
        <CloseIcon />
      </IconButton>
    </Stack>
    <SimpleForm sx={{ pt: 0, pb: 0 }} toolbar={<CreateEditToolbar isCreate={isCreate} redirectTo={redirectTo} />}>
      <Grid container rowSpacing={1} mb={1}>
        <Grid item>{children}</Grid>
      </Grid>
    </SimpleForm>
  </Box>
);

export default CreateEditWrapper;
