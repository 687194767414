import {
  TextInput,
  required,
  BooleanInput,
  DateInput,
  FormDataConsumer,
  FormDataConsumerRenderParams,
  maxValue,
  minValue,
  NumberInput,
  SelectArrayInput,
  SelectInput,
  Labeled,
} from 'react-admin';
import moment from 'moment';
import { Stack } from '@mui/material';

import useGetResource from 'app/utils/hooks/useGetResource';
import { Product } from '../products/ProductsTypeInterface';
import { Country } from '../regions/RegionsTypeInterface';
import { toCurrenciesChoices } from '../discounts/DiscountForm';

interface IGiftCodeForm {
  isCreate: boolean;
}

const GiftCodeForm = ({ isCreate }: IGiftCodeForm) => {
  const currencies = useGetResource<Country[]>({
    source: `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_AUTH_API_VERSION}/currencies?filter={"active": true}`,
  });
  const products = useGetResource<Product[]>({
    source: `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_AUTH_API_VERSION}/products?filter={"archived": false, "active": true}&range={}`,
  });

  return (
    <>
      <Stack direction='row' alignItems='center' spacing='10px'>
        <TextInput source='label' validate={required()} />
        {!isCreate && <BooleanInput source='deactivated' />}
      </Stack>
      <Labeled label={isCreate && '(Optional) Generates automatically'}>
        <TextInput source='code' />
      </Labeled>
      <Stack maxWidth={220}>
        <SelectArrayInput
          key='productIds'
          source='productIds'
          choices={products}
          optionText='skuId'
          validate={required()}
        />
      </Stack>
      <Stack direction='row' spacing='10px'>
        <NumberInput source='price' label='Discount' min={0} sx={{ maxWidth: 150, margin: 0 }} validate={required()} />
        <SelectInput
          key='currency'
          source='currency'
          choices={toCurrenciesChoices(currencies)}
          sx={{ minWidth: 150, margin: 0 }}
          defaultValue={currencies.find((i) => i.default)?.isoCode}
          validate={required()}
        />
      </Stack>
      <Stack direction='row' spacing='10px'>
        <FormDataConsumer>
          {({ formData, ...rest }: FormDataConsumerRenderParams) => (
            <NumberInput
              source='totalAmountUsage'
              label='Times Used'
              defaultValue={0}
              min={0}
              sx={{ maxWidth: 150, margin: 0 }}
              validate={[maxValue(formData.maxAmountUsage), required()]}
              {...rest}
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }: FormDataConsumerRenderParams) => (
            <NumberInput
              source='maxAmountUsage'
              label='Max Times Usage'
              defaultValue={10}
              min={0}
              sx={{ maxWidth: 150, margin: 0 }}
              validate={[minValue(formData.totalAmountUsage), required()]}
              {...rest}
            />
          )}
        </FormDataConsumer>
      </Stack>
      <Stack direction='row' spacing='10px'>
        <DateInput
          source='validFrom'
          defaultValue={Date.now()}
          sx={{ maxWidth: 150, margin: 0 }}
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData, ...rest }: FormDataConsumerRenderParams) => (
            <DateInput
              source='validUntil'
              label='Valid until (Optional)'
              sx={{ maxWidth: 150, margin: 0 }}
              validate={[
                minValue(
                  moment(formData.validFrom > Date.now() ? formData.validFrom : Date.now() + 3600 * 1000 * 24)
                    .toISOString()
                    .slice(0, 10),
                ),
              ]}
              {...rest}
            />
          )}
        </FormDataConsumer>
      </Stack>
    </>
  );
};

export default GiftCodeForm;
