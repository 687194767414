import { Identifier, BooleanField, Datagrid, DateField, TextField } from 'react-admin';

import BulkActionButtons from 'app/layout/BulkActionButtons';
import rowStyle from 'app/layout/rowStyle';
import IdArrayField from 'app/components/IdArrayField';
import AvatarField from 'app/components/AvatarField';
import { User } from './UserTypeInterface';

export interface IUserListDesktop {
  selectedRow?: Identifier;
}

const UsersListDesktop = ({ selectedRow }: IUserListDesktop) => (
  <Datagrid
    rowClick='edit'
    rowStyle={rowStyle(selectedRow)}
    optimized
    bulkActionButtons={<BulkActionButtons />}
    isRowSelectable={(record: User) => !record.archived}
  >
    <TextField source='username' />
    <IdArrayField source='roleIds' name='roles' apiV={process.env.REACT_APP_API_VERSION_ADMINS} labelField='type' />
    <TextField source='email' />
    <AvatarField source='avatar' sortable={false} />
    <TextField source='phone' />
    <BooleanField label='Is Active' source='active' />
    <BooleanField label='Is Archived' source='archived' />
    <DateField source='createdAt' />
  </Datagrid>
);

export default UsersListDesktop;
