import { CreateBase, CreateProps } from 'react-admin';

import CreateEditWrapper from 'app/layout/CreateEditWrapper';
import { Discount } from './DiscountsTypeInterface';
import DiscountForm from './DiscountForm';
import { trimStrings } from 'app/utils/functions';

interface IDiscountCreate extends CreateProps<Discount> {
  onCancel: () => void;
}

const DiscountCreate = ({ onCancel, ...props }: IDiscountCreate) => (
  <CreateBase {...props} transform={trimStrings}>
    <CreateEditWrapper redirectTo='discounts' title='Discount' isCreate onCancel={onCancel}>
      <DiscountForm isCreate />
    </CreateEditWrapper>
  </CreateBase>
);

export default DiscountCreate;
