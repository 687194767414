import {
  DeleteButton,
  RaRecord,
  SaveButton,
  ToolbarProps,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
} from 'react-admin';
import Toolbar from '@mui/material/Toolbar';

interface ICreateEditToolbar extends ToolbarProps {
  isCreate: boolean;
  redirectTo: string;
}

const CreateEditToolbar = ({ isCreate, redirectTo, ...props }: ICreateEditToolbar) => {
  const { resource } = props;
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();

  const record = useRecordContext<RaRecord>(props);

  if (!record && !isCreate) return null;
  return (
    <Toolbar
      sx={{
        backgroundColor: 'background.paper',
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: { sm: 0 },
        marginBottom: '30px',
      }}
      {...props}
    >
      <>
        <SaveButton
          mutationOptions={{
            onSuccess: () => {
              notify(isCreate ? 'ra.notification.created' : 'ra.notification.updated', {
                type: 'info',
                // eslint-disable-next-line camelcase
                messageArgs: { smart_count: 1 },
                undoable: !isCreate && true,
              });
              redirect('list', redirectTo);
              isCreate && refresh();
            },
          }}
          type='button'
        />
        {!isCreate && !record?.archived && <DeleteButton record={record} resource={resource} />}
      </>
    </Toolbar>
  );
};

export default CreateEditToolbar;
