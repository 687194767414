import { RaRecord, useRecordContext } from 'react-admin';
import { Chip, Grid } from '@mui/material';

import useGetResource from 'app/utils/hooks/useGetResource';

interface IIdArrayField {
  source: string;
  name: string;
  isFiltered?: boolean;
  filterProps?: string;
  apiV?: string;
  labelField?: string;
}

const IdArrayField = ({
  source,
  name,
  isFiltered,
  filterProps,
  apiV = process.env.REACT_APP_AUTH_API_VERSION,
  labelField = 'label',
}: IIdArrayField) => {
  const record = useRecordContext();
  const resource = useGetResource<RaRecord>({
    source: `${process.env.REACT_APP_API_DOMAIN}${apiV}/${name}${
      isFiltered ? '?filter={"archived": false, "active": true}&range={}' : ''
    }${filterProps || ''}`,
  });

  return (
    <Grid container rowSpacing={1} mb={1}>
      {resource &&
        resource
          .filter((i: RaRecord) => record[source].includes(i.id))
          .map((item: RaRecord) => (
            <Grid item key={item.id}>
              <Chip label={item[`${labelField}`]} />
            </Grid>
          ))}
    </Grid>
  );
};

export default IdArrayField;
