import { EditBase, EditProps } from 'react-admin';

import CreateEditWrapper from 'app/layout/CreateEditWrapper';
import { User } from './UserTypeInterface';
import UserForm from './UserForm';
import { trimStrings } from 'app/utils/functions';

interface IUserEdit extends EditProps<User> {
  onCancel: () => void;
  id?: string;
}

const UserEdit = ({ onCancel, id, ...props }: IUserEdit) => (
  <EditBase {...props} id={id} transform={trimStrings}>
    <CreateEditWrapper redirectTo='users' title='Admin' isCreate={false} onCancel={onCancel}>
      <UserForm isCreate={false} />
    </CreateEditWrapper>
  </EditBase>
);

export default UserEdit;
