import { CreateBase, CreateProps } from 'react-admin';

import CreateEditWrapper from 'app/layout/CreateEditWrapper';
import { GiftCode } from './GiftCodesTypeInterface';
import GiftCodeForm from './GiftCodeForm';
import { trimStrings } from 'app/utils/functions';

interface IGiftCodeCreate extends CreateProps<GiftCode> {
  onCancel: () => void;
}

const GiftCodeCreate = ({ onCancel, ...props }: IGiftCodeCreate) => (
  <CreateBase {...props} transform={trimStrings}>
    <CreateEditWrapper redirectTo='gift-codes' title='Gift Code' isCreate onCancel={onCancel}>
      <GiftCodeForm isCreate />
    </CreateEditWrapper>
  </CreateBase>
);

export default GiftCodeCreate;
