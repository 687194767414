import { RaRecord } from 'react-admin';

export const trimStrings = (data: RaRecord) => {
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === 'string') {
      data[key] = data[key].trim();
    }
  });
  return data;
};
