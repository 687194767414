import { AuthProvider } from 'react-admin';
import axios from 'axios';
import decodeJwt from 'jwt-decode';
import { User } from 'app/pages/users/UserTypeInterface';

interface IUserRoles {
  id: string;
  type: string;
}

declare interface UserTokenData {
  exp: number;
  iat: number;
  roles: IUserRoles[];
  username: string;
  id: string;
}

const authProvider: AuthProvider = {
  login: ({ username, password }) => {
    return axios
      .post(`${process.env.REACT_APP_AUTH_DOMAIN}${process.env.REACT_APP_AUTH_API_VERSION}/auth/login`, {
        username,
        password,
      })
      .then(function (response) {
        const decodedToken = decodeJwt(response.data.access_token) as UserTokenData;
        localStorage.setItem('token', response.data.access_token);
        localStorage.setItem('id', decodedToken.id);
        localStorage.setItem('username', JSON.stringify(decodedToken.username));
        localStorage.setItem('roles', JSON.stringify(decodedToken.roles));
        localStorage.setItem('exp', String(decodedToken.exp));
        localStorage.setItem('iat', String(decodedToken.iat));

        if (response.data.access_token)
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
      })
      .catch(function (error) {
        console.error('Failed to login', error);
        throw new Error(error.response.statusText);
      });
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('id');
    localStorage.removeItem('username');
    localStorage.removeItem('roles');
    localStorage.removeItem('exp');
    localStorage.removeItem('iat');
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const expireAt = localStorage.getItem('exp');
    if (localStorage.getItem('token') && Date.now() < Number(expireAt) * 1000) {
      return Promise.resolve();
    } else {
      return Promise.reject();
    }
  },
  getPermissions: () => {
    const roles = localStorage.getItem('roles');
    return roles ? Promise.resolve(roles) : Promise.reject();
  },
  getIdentity: () => {
    const result = axios
      .get(
        `${process.env.REACT_APP_AUTH_DOMAIN}${process.env.REACT_APP_AUTH_API_VERSION}/users/${localStorage.getItem(
          'id',
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .catch((error) => {
        console.log('Failed to get profile data', error);
        throw new Error('Failed to get profile data');
      });

    return Promise.resolve(result).then(({ data }: { data: User }) => {
      return {
        ...data,
        fullName: data.username,
      };
    });
  },
};

export default authProvider;
