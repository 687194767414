import { useCallback } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import { List } from 'react-admin';
import { Box, Drawer } from '@mui/material';

import usersFilters from './usersFilters';
import UsersListDesktop from './UsersListDesktop';
import UserEdit from './UserEdit';
import UserCreate from './UserCreate';

const UsersList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    navigate('/users');
  }, [navigate]);

  const match = matchPath('/users/:id', location.pathname);
  const rowNum = match && match.params.id;

  return (
    <Box>
      <List filters={usersFilters} hasCreate perPage={25} sort={{ field: 'username', order: 'asc' }}>
        <UsersListDesktop selectedRow={match ? parseInt(rowNum ? rowNum : '', 10) : undefined} />
      </List>
      <Drawer variant='persistent' open={!!match} anchor='right' onClose={handleClose} sx={{ zIndex: 100 }}>
        {match && rowNum !== 'create' ? (
          <UserEdit id={match.params.id} onCancel={handleClose} />
        ) : (
          <UserCreate onCancel={handleClose} />
        )}
      </Drawer>
    </Box>
  );
};

export default UsersList;
