import { useEffect, useState } from 'react';

interface IUseGetResource {
  source: string;
}

const useGetResource = <T,>({ source }: IUseGetResource) => {
  const [resource, setResource] = useState([]);
  const token = localStorage.getItem('token');

  useEffect(() => {
    fetch(source, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setResource(data));
  }, []);

  return resource as T;
};

export default useGetResource;
