import { Identifier, Datagrid, TextField, BooleanField, ChipField, DateField, FunctionField } from 'react-admin';

import BulkActionButtons from 'app/layout/BulkActionButtons';
import IdArrayField from 'app/components/IdArrayField';
import rowStyle from 'app/layout/rowStyle';
import { GiftCode } from './GiftCodesTypeInterface';

export interface IGiftCodeListDesktop {
  selectedRow?: Identifier;
}

const GiftCodesListDesktop = ({ selectedRow }: IGiftCodeListDesktop) => (
  <Datagrid
    rowClick='edit'
    rowStyle={rowStyle(selectedRow)}
    optimized
    bulkActionButtons={<BulkActionButtons />}
    isRowSelectable={(record: GiftCode) => !record.archived}
  >
    <TextField source='label' />
    <ChipField source='code' />
    <IdArrayField
      source='productIds'
      name='products'
      isFiltered
      filterProps='&sort=["product.id","ASC"]'
      labelField='skuId'
    />
    <FunctionField
      label='Discount'
      render={(record: GiftCode) => `${record.price} ${record.currency}`}
      sortBy='price'
    />
    <FunctionField
      label='Valid until'
      render={(record: GiftCode) => (record.validUntil ? <DateField source='validUntil' /> : 'Unlimited')}
      sortBy='validUntil'
    />
    <BooleanField source='deactivated' label='Is Deactivated' />
    <BooleanField source='archived' label='Is Archived' />
  </Datagrid>
);

export default GiftCodesListDesktop;
