import { BooleanInput, SearchInput, TextInput } from 'react-admin';

const usersFilters = [
  <SearchInput key={'search'} source='q' alwaysOn />,
  <TextInput key={'username'} source='username' />,
  <TextInput key={'email'} source='email' />,
  <TextInput key={'phone'} source='phone' />,
  <BooleanInput
    key={'archived'}
    source='archived'
    label='Show Archived'
    defaultValue={true}
    sx={{ alignSelf: 'center', margin: '0 10px' }}
  />,
];

export default usersFilters;
