import { EditProps, EditBase } from 'react-admin';

import CreateEditWrapper from 'app/layout/CreateEditWrapper';
import { Product } from './ProductsTypeInterface';
import ProductForm from './ProductForm';
import { trimStrings } from 'app/utils/functions';

interface IProductEdit extends EditProps<Product> {
  onCancel: () => void;
  id?: string;
}

const ProductEdit = ({ onCancel, id, ...props }: IProductEdit) => (
  <EditBase {...props} id={id} transform={trimStrings}>
    <CreateEditWrapper redirectTo='products' title='Product' isCreate={false} onCancel={onCancel}>
      <ProductForm isCreate={false} />
    </CreateEditWrapper>
  </EditBase>
);

export default ProductEdit;
