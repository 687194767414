interface Prices {
  country: string;
  currency: string;
}

export const validateProductCountry = (prices: Prices[]) => {
  if (prices.length > 1) {
    for (let i = 0; i < prices.length; i++) {
      for (let j = 0; j < prices.length; j++) {
        if (i !== j && prices[i].country === prices[j].country) {
          return 'Can`t select same country';
        }
      }
    }
  }
  return undefined;
};
