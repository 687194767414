import { BooleanInput, SearchInput, TextInput } from 'react-admin';

const giftCodesFilters = [
  <SearchInput key={'search'} source='q' alwaysOn />,
  <TextInput key={'code'} source='code' />,
  <TextInput key={'label'} source='label' />,
  <BooleanInput
    key={'archived'}
    source='archived'
    label='Show Archived'
    defaultValue={true}
    sx={{ alignSelf: 'center', margin: '0 10px' }}
  />,
];

export default giftCodesFilters;
