import {
  TextInput,
  required,
  DateInput,
  FormDataConsumer,
  FormDataConsumerRenderParams,
  maxValue,
  minValue,
  NumberInput,
  SelectInput,
  BooleanInput,
  SelectArrayInput,
  Labeled,
} from 'react-admin';
import moment from 'moment';
import { Stack } from '@mui/material';

import useGetResource from 'app/utils/hooks/useGetResource';
import { Country, Region } from '../regions/RegionsTypeInterface';

interface IDiscountForm {
  isCreate: boolean;
}

export const toCurrenciesChoices = (choices: Country[]) => {
  const selectChoices = choices.map((i) => i.isoCode);
  return selectChoices.filter((item, index) => selectChoices.indexOf(item) === index).map((i) => ({ id: i, name: i }));
};

const DiscountForm = ({ isCreate }: IDiscountForm) => {
  const currencies = useGetResource<Country[]>({
    source: `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_AUTH_API_VERSION}/currencies?filter={"active": true}`,
  });
  const regions = useGetResource<Region[]>({
    source: `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_AUTH_API_VERSION}/regions?filter={"archived": false}&range={}`,
  });

  return (
    <>
      <Stack direction='row' alignItems='center' spacing='10px'>
        <TextInput source='label' validate={required()} />
        {!isCreate && <BooleanInput source='deactivated' />}
      </Stack>
      <Labeled label={isCreate && '(Optional) Generates automatically'}>
        <TextInput source='code' />
      </Labeled>
      <Stack maxWidth={220}>
        <SelectArrayInput
          key='regionIds'
          source='regionIds'
          choices={regions}
          optionText='label'
          validate={required()}
        />
      </Stack>
      <SelectInput
        source='couponType'
        label='Discount type'
        choices={[
          { id: 'Fixed', name: 'Fixed' },
          { id: 'Percentage', name: 'Percentage' },
        ]}
        defaultValue='Percentage'
        validate={required()}
      />
      <Stack direction='row' spacing='10px'>
        <FormDataConsumer>
          {({ formData, ...rest }: FormDataConsumerRenderParams) => (
            <NumberInput
              source='price'
              label='Discount'
              min={0}
              max={formData.couponType === 'Percentage' ? 100 : undefined}
              sx={{ maxWidth: 150, margin: 0 }}
              validate={[maxValue(formData.couponType === 'Percentage' ? 100 : undefined), required()]}
              {...rest}
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }: FormDataConsumerRenderParams) =>
            formData.couponType === 'Fixed' ? (
              <SelectInput
                key='currency'
                source='currency'
                choices={toCurrenciesChoices(currencies)}
                sx={{ minWidth: 150, margin: 0 }}
                defaultValue={currencies.find((i) => i.default)?.isoCode}
                validate={required()}
                {...rest}
              />
            ) : (
              <Stack fontSize='2.3em'>%</Stack>
            )
          }
        </FormDataConsumer>
      </Stack>
      <Stack direction='row' spacing='10px'>
        <FormDataConsumer>
          {({ formData, ...rest }: FormDataConsumerRenderParams) => (
            <NumberInput
              source='totalAmountUsage'
              label='Times Used'
              defaultValue={0}
              min={0}
              sx={{ maxWidth: 150, margin: 0 }}
              validate={[maxValue(formData.maxAmountUsage), required()]}
              {...rest}
            />
          )}
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }: FormDataConsumerRenderParams) => (
            <NumberInput
              source='maxAmountUsage'
              label='Max Times Usage'
              defaultValue={10}
              min={0}
              sx={{ maxWidth: 150, margin: 0 }}
              validate={[minValue(formData.totalAmountUsage), required()]}
              {...rest}
            />
          )}
        </FormDataConsumer>
      </Stack>
      <Stack direction='row' spacing='10px'>
        <DateInput
          source='validFrom'
          defaultValue={Date.now()}
          sx={{ maxWidth: 150, margin: 0 }}
          validate={required()}
        />
        <FormDataConsumer>
          {({ formData, ...rest }: FormDataConsumerRenderParams) => (
            <DateInput
              source='validUntil'
              label='Valid until (Optional)'
              sx={{ maxWidth: 150, margin: 0 }}
              validate={[
                minValue(
                  moment(formData.validFrom > Date.now() ? formData.validFrom : Date.now() + 3600 * 1000 * 24)
                    .toISOString()
                    .slice(0, 10),
                ),
              ]}
              {...rest}
            />
          )}
        </FormDataConsumer>
      </Stack>
    </>
  );
};

export default DiscountForm;
