import { Identifier, Datagrid, TextField, ChipField, BooleanField, DateField, FunctionField } from 'react-admin';

import BulkActionButtons from 'app/layout/BulkActionButtons';
import IdArrayField from 'app/components/IdArrayField';
import rowStyle from 'app/layout/rowStyle';
import { Discount } from './DiscountsTypeInterface';

export interface IDiscountListDesktop {
  selectedRow?: Identifier;
}

const DiscountsListDesktop = ({ selectedRow }: IDiscountListDesktop) => (
  <Datagrid
    rowClick='edit'
    rowStyle={rowStyle(selectedRow)}
    optimized
    bulkActionButtons={<BulkActionButtons />}
    isRowSelectable={(record: Discount) => !record.archived}
  >
    <TextField source='label' />
    <ChipField source='code' />
    <IdArrayField source='regionIds' name='regions' isFiltered />
    <FunctionField
      label='Discount'
      render={(record: Discount) => `${record.price} ${record.currency || '%'}`}
      sortBy='price'
    />
    <FunctionField
      label='Valid until'
      render={(record: Discount) => (record.validUntil ? <DateField source='validUntil' /> : 'Unlimited')}
      sortBy='validUntil'
    />
    <BooleanField source='deactivated' label='Is Deactivated' />
    <BooleanField source='archived' label='Is Archived' />
  </Datagrid>
);

export default DiscountsListDesktop;
