import { BooleanInput, SearchInput, TextInput } from 'react-admin';

const productsFilters = [
  <SearchInput key={'search'} source='q' alwaysOn />,
  <TextInput key={'skuId'} source='skuId' label='Product Id' />,
  <BooleanInput
    key={'archived'}
    source='archived'
    label='Show Archived'
    defaultValue={true}
    sx={{ alignSelf: 'center', margin: '0 10px' }}
  />,
];

export default productsFilters;
