import { fetchUtils, DataProvider, LegacyDataProvider, FilterPayload, RaRecord } from 'react-admin';
import queryString from 'query-string';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const apiLists = String(process.env.REACT_APP_API_DOMAIN) + String(process.env.REACT_APP_AUTH_API_VERSION);
const apiUsers = String(process.env.REACT_APP_API_DOMAIN) + String(process.env.REACT_APP_API_VERSION_ADMINS);

export const dataProvider = {
  getList: (
    resource: string,
    params: { pagination: { page: number; perPage: number }; sort: { field: string; order: string }; filter: string },
  ) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify(params.filter),
      offset: page,
      limit: perPage,
    };
    const url = `${resource === 'users' ? apiUsers : apiLists}/${resource}?${queryString.stringify(query)}`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return httpClient(url).then(({ headers, json }: any) => ({
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
  },

  getOne: (resource: string, params: { id: string }) =>
    httpClient(`${resource === 'users' ? apiUsers : apiLists}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource: string, params: { ids: string[] }) => {
    const query = {
      filter: JSON.stringify({ ids: params.ids }),
    };
    const url = `${resource === 'users' ? apiUsers : apiLists}/${resource}?${queryString.stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (
    resource: string,
    params: {
      pagination: { page: number; perPage: number };
      sort: { field: string; order: string };
      filter: FilterPayload;
      target: string;
      id: string;
    },
  ) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${resource === 'users' ? apiUsers : apiLists}/${resource}?${queryString.stringify(query)}`;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return httpClient(url).then(({ headers, json }: any) => ({
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
  },

  create: (resource: string, params: { data: RaRecord }) =>
    httpClient(`${resource === 'users' ? apiUsers : apiLists}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    })),

  update: (resource: string, params: { id: string; data: RaRecord }) =>
    httpClient(`${resource === 'users' ? apiUsers : apiLists}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  updateMany: (resource: string, params: { ids: string; data: RaRecord }) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${resource === 'users' ? apiUsers : apiLists}/${resource}?${queryString.stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  delete: (resource: string, params: { id: string }) =>
    httpClient(`${resource === 'users' ? apiUsers : apiLists}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource: string, params: { ids: string[]; data: RaRecord }) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${resource === 'users' ? apiUsers : apiLists}/${resource}?${queryString.stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
} as unknown as DataProvider<string> | LegacyDataProvider | undefined;
