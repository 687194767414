import {
  TextInput,
  required,
  BooleanInput,
  FormDataConsumer,
  FormDataConsumerRenderParams,
  NumberInput,
  SelectArrayInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';
import { Stack } from '@mui/material';

import useGetResource from 'app/utils/hooks/useGetResource';
import { validateProductCountry } from 'app/utils/validators';
import { Country, Region } from '../regions/RegionsTypeInterface';
import { Technique } from '../techniques/TechniquesTypeInterface';

interface IProductForm {
  isCreate: boolean;
}

const ProductForm = ({ isCreate }: IProductForm) => {
  const regions = useGetResource<Region[]>({
    source: `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_AUTH_API_VERSION}/regions?filter={"archived": false}&range={}`,
  });
  const techniques = useGetResource<Technique[]>({
    source: `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_AUTH_API_VERSION}/techniques?filter={"archived": false}&range={}`,
  });
  const regionCountries = useGetResource<Country[]>({
    source: `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_AUTH_API_VERSION}/currencies?filter={"active": true}`,
  });

  const { setValue, resetField } = useFormContext();

  return (
    <>
      <Stack direction='row' alignItems='center' spacing='10px'>
        <TextInput source='skuId' validate={required()} label='Product Id' />
        {!isCreate && <BooleanInput source='active' label='Is Active' />}
      </Stack>
      <SelectArrayInput
        key='techniqueIds'
        source='techniqueIds'
        choices={techniques}
        optionText='label'
        validate={required()}
      />
      <ArrayInput source='prices' validate={required()}>
        <SimpleFormIterator>
          <FormDataConsumer>
            {({ getSource, ...rest }: FormDataConsumerRenderParams) => (
              <SelectInput
                key='regionId'
                source={(getSource && getSource('regionId')) || 'regionId'}
                choices={regions}
                optionText='label'
                validate={required()}
                onChange={() => resetField((getSource && getSource('country')) || 'country')}
                {...rest}
              />
            )}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, scopedFormData, getSource, ...rest }: FormDataConsumerRenderParams) => {
              const currentRegion = regions && regions.find((i) => i.id === scopedFormData.regionId);
              const countries = currentRegion && currentRegion.countries;
              return (
                scopedFormData.regionId && (
                  <Stack direction='row' spacing='10px'>
                    <SelectInput
                      key='country'
                      source={getSource && getSource('country')}
                      choices={
                        countries
                          ? regionCountries.filter((i) => countries.includes(i.countryIsoCode))
                          : regionCountries
                      }
                      validate={[() => validateProductCountry(formData.prices), required()]}
                      optionText='countryName'
                      optionValue='countryIsoCode'
                      sx={{ margin: 0 }}
                      onChange={(e) =>
                        setValue(
                          (getSource && getSource('currency')) || 'currency',
                          regionCountries.find((i) => i.countryIsoCode === e.target.value)?.isoCode,
                        )
                      }
                      {...rest}
                    />
                    {scopedFormData.country && (
                      <TextInput
                        sx={{ maxWidth: 100, margin: 0 }}
                        source={(getSource && getSource('currency')) || 'currency'}
                        {...rest}
                        disabled
                      />
                    )}
                  </Stack>
                )
              );
            }}
          </FormDataConsumer>
          <NumberInput source='price' min={0} validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>
    </>
  );
};

export default ProductForm;
