import { BooleanInput, SearchInput, TextInput } from 'react-admin';

const techniquesFilters = [
  <SearchInput key={'search'} source='q' alwaysOn />,
  <TextInput key={'id'} source='id' />,
  <TextInput key={'label'} source='label' />,
  <BooleanInput
    key={'archived'}
    source='archived'
    label='Show Archived'
    defaultValue={true}
    sx={{ alignSelf: 'center', margin: '0 10px' }}
  />,
];

export default techniquesFilters;
