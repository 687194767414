import { CreateBase, CreateProps } from 'react-admin';

import CreateEditWrapper from 'app/layout/CreateEditWrapper';
import { Technique } from './TechniquesTypeInterface';
import TechniqueForm from './TechniqueForm';
import { trimStrings } from 'app/utils/functions';

interface ITechniqueCreate extends CreateProps<Technique> {
  onCancel: () => void;
}

const TechniqueCreate = ({ onCancel, ...props }: ITechniqueCreate) => (
  <CreateBase {...props} transform={trimStrings}>
    <CreateEditWrapper redirectTo='techniques' title='Technique' isCreate onCancel={onCancel}>
      <TechniqueForm isCreate />
    </CreateEditWrapper>
  </CreateBase>
);

export default TechniqueCreate;
