import { useCallback } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router';
import { List } from 'react-admin';
import { Box, Drawer } from '@mui/material';

import techniquesFilters from './techniquesFilters';
import TechniquesListDesktop from './TechniquesListDesktop';
import TechniqueEdit from './TechniqueEdit';
import TechniqueCreate from './TechniqueCreate';

const TechniquesList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    navigate('/techniques');
  }, [navigate]);

  const match = matchPath('/techniques/:id', location.pathname);
  const rowNum = match && match.params.id;

  return (
    <Box>
      <List filters={techniquesFilters} hasCreate perPage={25} sort={{ field: 'archived', order: 'asc' }}>
        <TechniquesListDesktop selectedRow={match ? parseInt(rowNum ? rowNum : '', 10) : undefined} />
      </List>
      <Drawer variant='persistent' open={!!match} anchor='right' onClose={handleClose} sx={{ zIndex: 100 }}>
        {match && rowNum !== 'create' ? (
          <TechniqueEdit id={match.params.id} onCancel={handleClose} />
        ) : (
          <TechniqueCreate onCancel={handleClose} />
        )}
      </Drawer>
    </Box>
  );
};

export default TechniquesList;
