import { TextInput, required, BooleanInput, SelectArrayInput, email } from 'react-admin';
import { Stack } from '@mui/material';

import useGetResource from 'app/utils/hooks/useGetResource';

interface IUserForm {
  isCreate: boolean;
}

const UserForm = ({ isCreate }: IUserForm) => {
  const roles = useGetResource<string[]>({
    source: `${process.env.REACT_APP_API_DOMAIN}${process.env.REACT_APP_API_VERSION_ADMINS}/roles`,
  });

  return (
    <>
      <Stack direction='row' alignItems='center' spacing='10px'>
        <TextInput source='username' validate={required()} />
        {!isCreate && <BooleanInput label='Is Active' source='active' />}
      </Stack>
      <TextInput source='email' type='email' validate={[required(), email()]} />
      {isCreate && <TextInput source='password' type='password' validate={required()} />}
      <TextInput source='avatar' />
      <TextInput source='phone' type='phone' validate={required()} />
      <SelectArrayInput key={'roles'} source='roleIds' choices={roles} optionText='type' validate={required()} />
    </>
  );
};

export default UserForm;
