import { EditBase, EditProps } from 'react-admin';

import CreateEditWrapper from 'app/layout/CreateEditWrapper';
import { Discount } from './DiscountsTypeInterface';
import DiscountForm from './DiscountForm';
import { trimStrings } from 'app/utils/functions';

interface IDiscountEdit extends EditProps<Discount> {
  onCancel: () => void;
  id?: string;
}

const DiscountEdit = ({ onCancel, id, ...props }: IDiscountEdit) => (
  <EditBase {...props} id={id} transform={trimStrings}>
    <CreateEditWrapper redirectTo='discounts' title='Discount' isCreate={false} onCancel={onCancel}>
      <DiscountForm isCreate={false} />
    </CreateEditWrapper>
  </EditBase>
);

export default DiscountEdit;
