import { Identifier, RaRecord } from 'react-admin';
import { useTheme } from '@mui/material/styles';

const rowStyle = (selectedRow?: Identifier) => (record: RaRecord) => {
  const theme = useTheme();
  let style = {};
  if (!record) {
    return style;
  }
  if (selectedRow && selectedRow === record.id) {
    style = {
      ...style,
      backgroundColor: theme.palette.action.selected,
    };
  }
  return style;
};

export default rowStyle;
