import { TextInput, required } from 'react-admin';

interface ITechniqueForm {
  isCreate: boolean;
}

const TechniqueForm = ({ isCreate }: ITechniqueForm) => (
  <>
    <TextInput source='id' validate={required()} disabled={!isCreate} />
    <TextInput source='label' validate={required()} />
  </>
);

export default TechniqueForm;
