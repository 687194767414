import { Identifier, Datagrid, TextField, BooleanField } from 'react-admin';

import BulkActionButtons from 'app/layout/BulkActionButtons';
import rowStyle from 'app/layout/rowStyle';
import { Technique } from './TechniquesTypeInterface';

export interface ITechniqueListDesktop {
  selectedRow?: Identifier;
}

const TechniquesListDesktop = ({ selectedRow }: ITechniqueListDesktop) => (
  <Datagrid
    rowClick='edit'
    rowStyle={rowStyle(selectedRow)}
    optimized
    bulkActionButtons={<BulkActionButtons />}
    isRowSelectable={(record: Technique) => !record.archived}
  >
    <TextField source='id' />
    <TextField source='label' />
    <BooleanField source='archived' label='Is Archived' />
  </Datagrid>
);

export default TechniquesListDesktop;
